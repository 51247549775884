import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StateType } from '../../../reducers';
import { Status } from '../../../reducers';
// import { loginPost } from '../../../actions';
import { Redirect } from 'react-router-dom';

const Home = () => {
  // const dispatch = useDispatch();
  const loginResponse = useSelector((state: StateType) => state.loginData);
  const { errorMessage, status } = loginResponse;

  const [email, setEmail] = useState<string>('');

  const [form, setForm] = useState(0);
  useEffect(() => {}, []);

  const loginSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setForm(form + 1);
    if (email) {
      // dispatch(loginPost(email));
    }
  };

  const onchangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return <Redirect to="/register-user-company" />;

  // eslint-disable-next-line no-unreachable
  return (
    <div className="container">
      <div className="section section--loose">
        <div className="form-wrap user-register-form">
          {status === Status.FAILURE && errorMessage && (
            <div className="alert alert--warning">
              <div className="alert__message">{errorMessage}</div>
            </div>
          )}
          {/* status: {status === STATUS_TYPES.PENDING ? 'Pending...' : ''}
          errorMessage : {errorMessage} */}
          {/* <div className="loader panel  panel--bordered panel--raised dbl-padding base-margin-bottom">
            <div className="loading-dots" aria-label="Loading, please wait...">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div> */}
          {form === 1 && 0 && (
            <div className="register-message-wrap base-margin-bottom">
              <div className="row base-margin-top">
                <div className="col-md-12">
                  <div className="panel  panel--bordered panel--raised dbl-padding">
                    <h6>Your Company is registered with Us!</h6>
                    <p>
                      To create a cisco.com account, please click on sign-up in
                      the next screen.
                    </p>
                    <button className="btn btn--primary">Continue</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {form === 2 ||
            (1 && (
              <div className="register-message-wrap  base-margin-bottom">
                <div className="row base-margin-top">
                  <div className="col-md-12">
                    <div className="panel  panel--bordered panel--raised dbl-padding">
                      <h6>Your Company is not registered with Us!</h6>
                      <p>
                        Please <a>click</a> here to get help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {/* <div className="row">
            <div className="col-md-6">
              <form onSubmit={loginSubmit}>
                <div className="panel panel--bordered-right  base-margin-bottom">
                  <h5>New Users</h5>
                  <p>
                    Enter your Email address that is associated with your
                    company. <a>Need help</a>
                  </p>
                  <div className="form-group base-margin-bottom">
                    <div className="form-group__text">
                      <input
                        id="input-email"
                        type="email"
                        value={email}
                        onChange={onchangeEmail}
                        required
                        disabled={status === Status.PENDING}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={status === Status.PENDING}
                      className="btn btn--ghost"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <div className="panel">
                <h5>Existing Users</h5>
                <p>
                  Already have an account? <a>Log in</a> now. <a>Need help</a>{' '}
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Home;
