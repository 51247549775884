import React, { ReactNode } from 'react';
// import { Link } from 'react-router-dom';

export interface MainLayoutProps {
  header: ReactNode;
  children: ReactNode;
  footer: ReactNode;
}

const MainLayout = ({ header, children, footer }: MainLayoutProps) => {
  return (
    <div>
      {header}
      <div className="content">{children}</div>
      {footer}
    </div>
  );
};
export default MainLayout;
