import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PlaceHolderBox from '../../atoms/PlaceHolderBox';
import Loader from '../../atoms/Loader';
import { fetchCompanyTypes, fetchComapanyList } from '../../../actions';
import { AppState, Status } from '../../../reducers';

const RegisterUserCompany = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [isCompanyShow, setIsCompanyShow] = useState(false);
  const [isOrgShow, setIsOrgShow] = useState(false);

  const companyRegisterData = useSelector(
    (state: AppState) => state.companyRegisterData
  );
  const { companyTypeStatus } = companyRegisterData;

  useEffect(() => {
    if (formData.companyName) {
      setIsOrgShow(true);
    } else {
      setIsOrgShow(false);
    }
    console.log('formData', formData);
  }, [formData.companyName]);

  useEffect(() => {
    if (formData.companyType) {
      setIsCompanyShow(true);
      dispatch(fetchComapanyList(formData.companyType));
    } else {
      setIsCompanyShow(false);
    }
  }, [formData.companyType]);

  useEffect(() => {
    dispatch(fetchCompanyTypes());
  }, []);

  const companies = [
    {
      name: 'HON HAI PRECISION INDUSTRY CO LTD-RFQ'
    },
    {
      name: 'CELESTICA HOLDINGS PTE LTD',
      child: 'CELESTICA HOLDINGS PTE LTD'
    }
  ];

  const companyTypes = [
    'Contract Manufacturer',
    'Provisioning Supplier',
    'Third Party Logistic provider',
    'Company Supplier',
    'Carrier'
  ];

  const primaryOrganization = ['AWV', 'BFT', 'CTH'];

  const secondaryOrganization = ['AWV', 'BFT', 'CTH'];

  const registerSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSuccess(true);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      ...{ [event.target.name]: event.target.value }
    });
  };

  return (
    <div className="container">
      <form name="companyRegisterform" onSubmit={registerSubmit}>
        <div className="section">
          <div className="base-margin-bottom">
            <h5>Welcome back - Username !!</h5>
          </div>
          <div className="form-wrap base-margin-top">
            {success && (
              <div className="alert alert--success dbl-padding">
                <div className="alert__message">
                  <h6>
                    Your profile creation request has been submitted
                    successfully!!
                  </h6>
                  <p>You will be notified once it is approved.</p>
                </div>
              </div>
            )}

            {!success && (
              <>
                <div className="panel  panel--bordered-bottom base-padding">
                  <div className="row">
                    <div className="col-md-4 col--bordered-right">
                      <div className="panel company-form-panel">
                        {companyTypeStatus === Status.PENDING && (
                          <div className="center-holder-wrap">
                            <Loader />
                          </div>
                        )}

                        <h6>Please choose a company type</h6>

                        {companyTypeStatus === Status.SUCCESS ||
                          (companyTypeStatus === Status.FAILURE && (
                            <div className="base-padding-left">
                              {companyTypes.map((value, index) => (
                                <div
                                  key={`companyType${index}`}
                                  className="form-group base-margin-top"
                                >
                                  <label className="radio">
                                    <input
                                      type="radio"
                                      name="companyType"
                                      value={value}
                                      onChange={onChange}
                                    />
                                    <span className="radio__input"></span>
                                    <span className="radio__label">
                                      {value}
                                    </span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="col-md-4 col--bordered-right">
                      <div className="panel company-form-panel">
                        {!isCompanyShow && (
                          <PlaceHolderBox title="Please Select Company Type" />
                        )}
                        {isCompanyShow && (
                          <div className="">
                            <h6>Please choose a Company</h6>
                            <div className="base-padding-left company-list-wrap">
                              {companies.map(({ name, child }, index) => (
                                <div key={`companyType${index}`}>
                                  <div className="form-group base-margin-top">
                                    <label className="radio">
                                      <input
                                        type="radio"
                                        name="companyName"
                                        value={name}
                                        onChange={onChange}
                                      />
                                      <span className="radio__input"></span>
                                      <span className="radio__label">
                                        {name}
                                      </span>
                                    </label>
                                  </div>
                                  {child && (
                                    <div className="base-padding-left qtr-margin-top">
                                      <div className="form-group">
                                        <label className="radio">
                                          <input
                                            type="radio"
                                            name="companyName"
                                            value={child}
                                            onChange={onChange}
                                          />
                                          <span className="radio__input"></span>
                                          <span className="radio__label">
                                            {child}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="panel company-form-panel">
                        {!isOrgShow && (
                          <PlaceHolderBox
                            title={
                              isCompanyShow
                                ? 'Please Select Company'
                                : 'Please Select Company Type'
                            }
                          />
                        )}
                        {isCompanyShow && isOrgShow && (
                          <>
                            <div className="">
                              <h6>Primary Oranization</h6>
                              <div className="base-padding-left organization-form-wrap">
                                {primaryOrganization.map((value, index) => (
                                  <div
                                    key={`primaryOrganization${index}`}
                                    className="form-group base-margin-top"
                                  >
                                    <label className="radio">
                                      <input
                                        type="radio"
                                        name="primaryOrganization"
                                        value={value}
                                        onChange={onChange}
                                      />
                                      <span className="radio__input"></span>
                                      <span className="radio__label">
                                        {value}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="base-margin-top">
                              <h6>
                                Secandary Oranization -{' '}
                                <small>( Optional )</small>
                              </h6>
                              <div className="base-padding-left organization-form-wrap">
                                {secondaryOrganization.map((value, index) => (
                                  <div
                                    key={`secondaryOrganization${index}`}
                                    className="form-group base-margin-top"
                                  >
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        name="secondaryOrganization[]"
                                        value={value}
                                        onChange={onChange}
                                      />
                                      <span className="checkbox__input"></span>
                                      <span className="checkbox__label">
                                        {value}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="base-margin-top text-center">
                  <button className="btn btn--primary">Submit</button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default RegisterUserCompany;
