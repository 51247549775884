import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from '@reduxjs/toolkit';

import {
  companyRegisterReducer,
  CompanyRegsiterState,
  CompanyRegister
} from './companyReducer';
import { loginReducer, LoginState } from './loginReducer';

export * from './StatusTypes.interface';
export interface AppState {
  companyRegisterData: CompanyRegsiterState<CompanyRegister>;
  loginData: LoginState;
}
export const reducer = combineReducers<AppState>({
  companyRegisterData: companyRegisterReducer,
  loginData: loginReducer
});

const store: Store<AppState> = createStore(reducer, applyMiddleware(thunk));
export default store;

export type StateType = ReturnType<typeof store.getState>;
