import { LoginAction, LoginState } from './index';
import { ACTION_TYPES } from '../../actions/ActionTypes.interface';
import { Status } from '../StatusTypes.interface';

const initialState: LoginState = {};

export const loginReducer: any = (
  state: LoginState = initialState,
  action: LoginAction
) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_COMPLETE: {
      return {
        ...state,
        ...{ status: Status.SUCCESS, response: action.payload }
      };
    }
    case ACTION_TYPES.LOGIN_START: {
      return { ...state, ...{ status: Status.PENDING } };
    }
    case ACTION_TYPES.LOGIN_FAILURE: {
      return {
        ...state,
        ...{ status: Status.FAILURE, errorMessage: action.payload }
      };
    }
    default:
      return state;
  }
};
