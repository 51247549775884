import {
  CompanyRegister,
  CompanyRegisterAction,
  CompanyRegsiterState
} from './index';
import { ACTION_TYPES } from '../../actions/ActionTypes.interface';
import { Status } from '../StatusTypes.interface';

const initialState: CompanyRegsiterState<CompanyRegister> = {};

export const companyRegisterReducer: any = (
  state: CompanyRegsiterState<CompanyRegister> = initialState,
  action: CompanyRegisterAction<CompanyRegister>
) => {
  switch (action.type) {
    case ACTION_TYPES.COMPANY_TYPE_COMPLETE: {
      return {
        ...state,
        ...{
          companyTypeStatus: Status.SUCCESS,
          companyTypes: action.payload
        }
      };
    }
    case ACTION_TYPES.COMPANY_TYPE_START: {
      return { ...state, ...{ companyTypeStatus: Status.PENDING } };
    }
    case ACTION_TYPES.COMPANY_TYPE_FAILURE: {
      return {
        ...state,
        ...{ companyTypeStatus: Status.FAILURE }
      };
    }
    case ACTION_TYPES.COMPANY_LIST_COMPLETE: {
      return {
        ...state,
        ...{
          companyListStatus: Status.SUCCESS,
          companyList: action.payload
        }
      };
    }
    case ACTION_TYPES.COMPANY_LIST_START: {
      return { ...state, ...{ companyListStatus: Status.PENDING } };
    }
    case ACTION_TYPES.COMPANY_LIST_FAILURE: {
      return {
        ...state,
        ...{ companyListStatus: Status.FAILURE }
      };
    }

    default:
      return state;
  }
};
