export const ACTION_TYPES = {
  COMPANY_TYPE_START: 'COMPANY_TYPE_START',
  COMPANY_TYPE_COMPLETE: 'COMPANY_TYPE_COMPLETE',
  COMPANY_TYPE_FAILURE: 'COMPANY_TYPE_FAILURE',
  COMPANY_LIST_START: 'COMPANY_LIST_START',
  COMPANY_LIST_COMPLETE: 'COMPANY_LIST_COMPLETE',
  COMPANY_LIST_FAILURE: 'COMPANY_LIST_FAILURE',
  LOGIN_START: 'LOGIN_START',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  LOGIN_FAILURE: 'LOGIN_FAILURE'
};
