import React from 'react';
import { Link } from 'react-router-dom';

import './logout.css';

const Logout = () => {
  return (
    <div className="container base-margin-top">
      <div className="panel panel--loose panel--raised logiout-panel panel--bordered">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center base-margin-bottom base-margin-top">
              <h1 className="display-4">
                <span className="icon-cisco color-primary"></span>
              </h1>
            </div>
            <hr />
            <div className="panel panel--loose dbl-padding text-center">
              <h3 className="no-margin base-margin-bottom">
                Thank you for visiting
              </h3>

              <h5 className="base-margin-bottom">You are now logged out.</h5>
              <Link to="/">Return to SCREG</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Logout;
