import React, { useEffect } from 'react';

const Footer = () => {
  useEffect(() => {}, []);

  return (
    <footer className="footer footer--default">
      <div className="footer__links">
        <ul className="list list--inline">
          <li>
            <a
              href="http://www.cisco.com/cisco/web/siteassets/contacts/index.html"
              target="_blank"
              rel="noreferrer"
            >
              Contacts
            </a>
          </li>
          <li>
            <a
              href="https://secure.opinionlab.com/ccc01/o.asp?id=jBjOhqOJ"
              target="_blank"
              rel="noreferrer"
            >
              Feedback
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/help.html"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li>
            <a
              href="http://www.cisco.com/c/en/us/about/sitemap.html"
              target="_blank"
              rel="noreferrer"
            >
              Site Map
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Statement
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.cisco.com/c/en/us/about/legal/trademarks.html"
              target="_blank"
              rel="noreferrer"
            >
              Trademarks
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
