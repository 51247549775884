import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Header = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userName, setUserName] = useState<string | null>(null);

  const logoutUrl = window.location.origin + '/logout';

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      console.log('authState', authState);
      setUserName(authState?.idToken?.claims.name || null);
    } else {
      setUserName(null);
    }
  }, [authState]);

  const onClickLogout = () => {
    oktaAuth.signOut({ postLogoutRedirectUri: logoutUrl });
  };

  return (
    <header className="header">
      <div className="container-fluid">
        <div className="header-panels">
          <div className="header-panel hidden-lg-down">
            <a
              className="header__logo"
              href="http://www.cisco.com"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              <span className="icon-cisco"></span>{' '}
            </a>
            <h1 className="header__title">SC User Registration</h1>
          </div>
          <div className="header-panel header-panel--center base-margin-left base-margin-right">
            <a
              className="header__logo hidden-xl-up"
              href="http://www.cisco.com"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon-cisco"></span>
            </a>
          </div>
          <div className="header-panel header-panel--right">
            {userName && (
              <div className="base-margin-right">
                <span>{userName}</span>
              </div>
            )}
            <a className="header-item" title="Help">
              <span className="icon-help-alt"></span>
            </a>
            <a onClick={onClickLogout} className="header-item" title="Logout">
              <button className="btn btn--ghost btn--circle">
                <span className="icon-sign-out"></span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
