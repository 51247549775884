import React from 'react';

export interface PlaceHolderBoxProps {
  title: string;
}

const PlaceHolderBox = ({ title }: PlaceHolderBoxProps) => {
  return (
    <div className="center-holder-wrap">
      <div className="place-holder-box">
        <div className="place-holder-title">{title}</div>
      </div>
    </div>
  );
};
export default PlaceHolderBox;
