import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  withRouter,
  RouteProps
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';

import './App.css';
import MainLayout from './components/layouts/MainLayout';
import Home from './components/pages/Home';
// import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import Header from './components/templates/Header';
import Footer from './components/templates/Footer';
import RegisterUserCompany from './components/pages/RegisterUserCompany';
import Store from './reducers';

const oktaAuth = new OktaAuth({
  issuer: `https://int-id.cisco.com/oauth2/default`,
  clientId: '5c3efc95-69f4-4b0e-9954-a1860237beb5',
  redirectUri: window.location.origin + '/login/callback'
});

const Layout = ({ children }: any) => {
  return (
    <MainLayout header={<Header />} footer={<Footer />}>
      {children}
    </MainLayout>
  );
};

function App() {
  const history = useHistory();

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri?: string
  ) => {
    history.push(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Provider store={Store}>
      <div className="app">
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <SecureRoute exact path="/" component={Home} />
          {/* <Redirect from="/" to="/register-user-company" /> */}
          <SecureRoute
            exact
            path="/login-error"
            component={(prop: RouteProps) => (
              <Layout {...prop}>
                <Home />
              </Layout>
            )}
          />
          <SecureRoute
            exact
            path="/register-user-company"
            component={(prop: RouteProps) => (
              <Layout {...prop}>
                <RegisterUserCompany />
              </Layout>
            )}
          />
          <Route path="/login/callback" component={LoginCallback} />
          <Route path="/logout" component={Logout} />
        </Security>
      </div>
    </Provider>
  );
}

const AppWithRouterAccess = withRouter(App);
class RouterApp extends Component {
  render() {
    return (
      <Router>
        <AppWithRouterAccess />
      </Router>
    );
  }
}

export default RouterApp;
