import { ACTION_TYPES } from './ActionTypes.interface';

import { config } from '../config/config';

export const fetchCompanyTypes = () => {
  return (dispatch: any) => {
    const { apiUrl } = config;
    dispatch({ type: ACTION_TYPES.COMPANY_TYPE_START });
    return fetch(`${apiUrl}/companyTypeList`)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        dispatch({ type: ACTION_TYPES.COMPANY_TYPE_COMPLETE, payload: res });
        return res;
      })
      .catch((error) => dispatch({ type: ACTION_TYPES.COMPANY_TYPE_FAILURE }));
  };
};

export const fetchComapanyList = (companyType: string) => {
  return (dispatch: any) => {
    const { apiUrl } = config;
    dispatch({ type: ACTION_TYPES.COMPANY_LIST_START });
    return fetch(`${apiUrl}/companyList/${companyType}`)
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        dispatch({ type: ACTION_TYPES.COMPANY_LIST_COMPLETE, payload: res });
        return res;
      })
      .catch((error) => dispatch({ type: ACTION_TYPES.COMPANY_LIST_FAILURE }));
  };
};

// Handle HTTP errors since fetch won't.
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
