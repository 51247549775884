import React from 'react';

export interface LoaderProps {
  arialLabel?: string;
}

const Loader = ({ arialLabel }: LoaderProps) => {
  return (
    <div
      className="loading-dots"
      aria-label={arialLabel || 'Loading, please wait...'}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
export default Loader;
